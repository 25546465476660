import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { CreditValidation } from "./credit_validation.js";
import { Helmet } from "react-helmet";
import { Row, Col, Image, Table, Card } from "react-bootstrap";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const profileStyle = {
  fontSize: "1.2em",
};

const TechnologyList = [
  {
    name: "C / C++",
    text: "競技プログラミング ゲーム制作（DxLib, OpenSiv3D）など。母語",
    rank: 3,
  },
  {
    name: "Python",
    text: "人工知能（TensorFlow） バックエンド開発（Django, FastAPI）など",
    rank: 3,
  },
  {
    name: "React.js",
    text: "フロントエンド開発",
    rank: 2,
  },
  {
    name: "Node.js",
    text: "バックエンド開発",
    rank: 2,
  },
  {
    name: "MySQL",
    text: "データベース。勉強中",
    rank: 2,
  },
  {
    name: "(System) Verilog",
    text: "マイクロプロセッサ設計、FPGA開発",
    rank: 2,
  },
  {
    name: "Matlab",
    text: "無線通信、SDR開発",
    rank: 1,
  },
  {
    name: "LaTeX",
    text: "レポート・文書作成",
    rank: 3,
  },
  {
    name: "Git",
    text: "バージョン管理",
    rank: 3,
  },
];

function Profile() {
  return (
    <>
      <Row style={{ display: "flex" }}>
        <Col xs={12} lg={4}>
          <Image src="profile.jpg" thumbnail />
        </Col>
        <Col xs={12} lg={8}>
          <h2>Profile</h2>
          <Table borderless>
            <tbody style={profileStyle}>
              <tr>
                <td>名前</td>
                <td>佐藤龍吾 （さとう りゅうご / Ryugo SATO）</td>
              </tr>
              <tr>
                <td>生年月日</td>
                <td>2002年6月1日</td>
              </tr>
            </tbody>
          </Table>
          <h2>Education</h2>
          <Table borderless>
            <tbody style={profileStyle}>
              <tr>
                <td>2015/4～2021/3</td>
                <td>麻布中学校・高等学校</td>
              </tr>
              <tr>
                <td>2021/4～2023/3</td>
                <td>東京大学教養学部理科一類</td>
              </tr>
              <tr>
                <td>2023/4～</td>
                <td>東京大学工学部電子情報工学科</td>
              </tr>
            </tbody>
          </Table>
          <h2>Awards</h2>
          <Table borderless>
            <tbody style={profileStyle}>
              <tr>
                <td>パソコン甲子園</td>
                <td>
                  <ul class="list-unstyled">
                    <li>新人賞（2018）</li>
                    <li>5位入賞（2019）</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>PG BATTLE</td>
                <td>高校、中学、小学の部優勝（2019）</td>
              </tr>
              <tr>
                <td>日本情報オリンピック予選</td>
                <td>優秀賞（2017, 2018, 2019）</td>
              </tr>
              <tr>
                <td>日本数学オリンピック予選</td>
                <td>Aランク（2020）</td>
              </tr>
            </tbody>
          </Table>
          <h2>Technology Stack</h2>
          <Row style={{ display: "flex" }}>
            {TechnologyList.map((item) => (
              <Col xs={12} lg={6}>
                <Card style={{ height: "100%" }}>
                  <Card.Body>
                    <Card.Title>{item.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      {"★".repeat(item.rank)}
                    </Card.Subtitle>
                    <Card.Text>{item.text}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <h2>Publications</h2>
          <Table borderless>
            <tbody style={profileStyle}>
              <tr>
                <td>2024/3</td>
                <td>応用物理学会春季学術講演会</td>
                <td>
                  <a href="https://pub.confit.atlas.jp/ja/event/jsap2024s/presentation/24p-31A-4">
                  不揮発性メモリの量子化・書き込みばらつきを考慮した学習による
                  <br />
                  Computation-in-Memoryにおける深層強化学習の性能向上
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
          <h2>Links</h2>
          <Table borderless>
            <tbody style={profileStyle}>
              <tr>
                <td>
                  <a href="https://github.com/SugarDragon5">GitHub</a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="/eeic">
                    EEIC2023用 研究室振り分け・卒業要件チェッカー
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                <a href="https://2024.eeic.jp">
                  近未来体験2024 公式Web(実装を担当)
                </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

function App() {
  return (
    <>
      <Helmet>
        <title>Ryugo Web</title>
      </Helmet>
      <div className="App-header">
        <h1>Ryugo Web〈Under Construction〉</h1>
      </div>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Profile />} />
            <Route path="/eeic" element={<CreditValidation />} />
          </Routes>
        </BrowserRouter>
      </div>
      <div className="App-footer"></div>
    </>
  );
}

export default App;
