export const subjects = [
    {
        "id": "EE2c01L1",
        "name": "電気回路理論第一",
        "credit": 4.0,
        "A1": "◎",
        "A2": "◎",
        "AS": "◎",
        "BS": "◎",
        "B1": "◎",
        "B2": "◎"
    },
    {
        "id": "EE2101S1",
        "name": "電気電子数学演習",
        "credit": 3.0,
        "A1": "◎",
        "A2": "◎",
        "AS": "◎",
        "BS": "◎",
        "B1": "◎",
        "B2": "◎"
    },
    {
        "id": "EE2d01L1",
        "name": "ソフトウエアI",
        "credit": 2.0,
        "A1": "◎",
        "A2": "◎",
        "AS": "◎",
        "BS": "◎",
        "B1": "◎",
        "B2": "◎"
    },
    {
        "id": "EE2d02L1",
        "name": "ソフトウエアII",
        "credit": 2.0,
        "A1": "◎",
        "A2": "◎",
        "AS": "◎",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE2201L1",
        "name": "電気磁気学I",
        "credit": 2.0,
        "A1": "◎",
        "A2": "◎",
        "AS": "◎",
        "BS": "◎",
        "B1": "◎",
        "B2": "◎"
    },
    {
        "id": "EE2202L1",
        "name": "電気磁気学II",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "◎",
        "B1": "◎",
        "B2": "◎"
    },
    {
        "id": "EE2c02L1",
        "name": "ディジタル回路",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE2601L1",
        "name": "電気電子計測",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE2d03S1",
        "name": "プログラミング基礎演習",
        "credit": 1.5,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE2c03L1",
        "name": "電子デバイス基礎",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE2102L1",
        "name": "信号解析基礎",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE2d04L1",
        "name": "情報通信理論",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "EE2203L1",
        "name": "電子基礎物理",
        "credit": 4.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE2501L1",
        "name": "エネルギー工学",
        "credit": 2.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE2c08L1",
        "name": "電気機器学基礎",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "CO2114L1",
        "name": "数学１Ｄ",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "CO2f00L1",
        "name": "生命科学概論",
        "credit": 2.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "CO2141L1",
        "name": "数理手法Ⅰ",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": ""
    },
    {
        "id": "CO2145L1",
        "name": "数理手法Ｖ",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": ""
    },
    {
        "id": "CO2149L1",
        "name": "数理手法VIII",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": ""
    },
    {
        "id": "EE3901E1",
        "name": "電気電子情報実験・演習第一",
        "credit": 6.0,
        "A1": "◎",
        "A2": "◎",
        "AS": "◎",
        "BS": "◎",
        "B1": "◎",
        "B2": "◎"
    },
    {
        "id": "EE3602L1",
        "name": "制御工学第一",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE3c06L1",
        "name": "電磁波工学",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE3c04L1",
        "name": "電気回路理論第二",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE3c05L1",
        "name": "電子回路Ｉ",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE3d05L1",
        "name": "ハードウエア設計論",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE3d07L1",
        "name": "コンピュータアーキテクチャ",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "EE3d06L1",
        "name": "ネットワーク工学概論",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "EE3c07L1",
        "name": "半導体デバイス工学",
        "credit": 2.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE3d08L1",
        "name": "アルゴリズム",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "",
        "B1": "",
        "B2": ""
    },
    {
        "id": "EE3103L1",
        "name": "信号処理工学",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "",
        "B1": "",
        "B2": ""
    },
    {
        "id": "EE3204L1",
        "name": "電子物性基礎",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "CO3127L1",
        "name": "数学２Ｇ",
        "credit": 2.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE3d26L1",
        "name": "統計的機械学習",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "CO3124L1",
        "name": "数学２Ｄ",
        "credit": 4.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE3c14L1",
        "name": "電力システム工学第一",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE3c13L1",
        "name": "電離気体論",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE3401L1",
        "name": "電気電子情報工学倫理",
        "credit": 1.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "EE3903P1",
        "name": "電気電子情報工学実習",
        "credit": 1.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "CO3008S2",
        "name": "Workshop towards communicating engineers（水曜開講）",
        "credit": 1.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "EE3902E1",
        "name": "電気電子情報実験・演習第二",
        "credit": 6.0,
        "A1": "◎",
        "A2": "◎",
        "AS": "◎",
        "BS": "◎",
        "B1": "◎",
        "B2": "◎"
    },
    {
        "id": "EE3d17L1",
        "name": "計算論",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "※",
        "B2": ""
    },
    {
        "id": "EE3603L1",
        "name": "制御工学第二",
        "credit": 2.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "",
        "B2": "○"
    },
    {
        "id": "EE3d13L1",
        "name": "映像メディア工学",
        "credit": 2.0,
        "A1": "○",
        "A2": "※",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": ""
    },
    {
        "id": "EE3c27L1",
        "name": "ＶＬＳＩ工学Ⅰ",
        "credit": 2.0,
        "A1": "",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": ""
    },
    {
        "id": "EE3503L1",
        "name": "エネルギー変換工学",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": "○"
    },
    {
        "id": "EE3214L1",
        "name": "量子情報技術入門",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "※"
    },
    {
        "id": "EE3d16L1",
        "name": "オペレーティングシステム",
        "credit": 2.0,
        "A1": "※",
        "A2": "○",
        "AS": "※",
        "BS": "※",
        "B1": "",
        "B2": ""
    },
    {
        "id": "EE3c15L1",
        "name": "電磁界応用工学",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "",
        "B1": "※",
        "B2": "○"
    },
    {
        "id": "EE3d12L1",
        "name": "人工知能",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": ""
    },
    {
        "id": "EE3c10L1",
        "name": "光電子工学Ｉ",
        "credit": 2.0,
        "A1": "",
        "A2": "※",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": ""
    },
    {
        "id": "CO3130L1",
        "name": "数学３",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": ""
    },
    {
        "id": "EE3d14L1",
        "name": "ヒューマンインターフェース工学",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": ""
    },
    {
        "id": "EE3212L1",
        "name": "量子力学Ｉ",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": ""
    },
    {
        "id": "EE3702L1",
        "name": "システム数理工学",
        "credit": 2.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "",
        "B2": "○"
    },
    {
        "id": "CO3143L1",
        "name": "数理手法III",
        "credit": 2.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "",
        "B2": ""
    },
    {
        "id": "EE3d11L1",
        "name": "情報通信工学",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "※",
        "B2": ""
    },
    {
        "id": "EE3704L1",
        "name": "分散システム",
        "credit": 2.0,
        "A1": "※",
        "A2": "○",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": ""
    },
    {
        "id": "EE3c12L1",
        "name": "光電子デバイス",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": ""
    },
    {
        "id": "EE3502L1",
        "name": "パワーエレクトロニクス",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": "○"
    },
    {
        "id": "EE3d18L1",
        "name": "メディアコンテンツ特別講義II",
        "credit": 1.0,
        "A1": "※",
        "A2": "※",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": ""
    },
    {
        "id": "EE3d15L1",
        "name": "言語・音声情報処理",
        "credit": 2.0,
        "A1": "○",
        "A2": "※",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": ""
    },
    {
        "id": "EE3c09L1",
        "name": "電子回路II",
        "credit": 2.0,
        "A1": "",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "※"
    },
    {
        "id": "EE3801L1",
        "name": "電子情報機器学",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": "○"
    },
    {
        "id": "EE3206L1",
        "name": "電子物性第一",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "※",
        "BS": "※",
        "B1": "○",
        "B2": ""
    },
    {
        "id": "EE3d10L1",
        "name": "無線通信応用工学",
        "credit": 2.0,
        "A1": "○",
        "A2": "○",
        "AS": "○",
        "BS": "○",
        "B1": "○",
        "B2": ""
    },
    {
        "id": "EE3802S1",
        "name": "電気機器ＣＡＤ演習",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": "※"
    },
    {
        "id": "EE3210L1",
        "name": "プラズマ理工学",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": "○"
    },
    {
        "id": "EE3c24L1",
        "name": "電力システム工学第二",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": "○"
    },
    {
        "id": "EE3c22L1",
        "name": "高電圧工学",
        "credit": 2.0,
        "A1": "",
        "A2": "",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": "○"
    },
    {
        "id": "EE3001S1",
        "name": "Special Training for English Academic Communication",
        "credit": 1.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "EE4904T1",
        "name": "卒業論文",
        "credit": 12.0,
        "A1": "◎",
        "A2": "◎",
        "AS": "◎",
        "BS": "◎",
        "B1": "◎",
        "B2": "◎"
    },
    {
        "id": "EE4213L1",
        "name": "量子力学II",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4d19L1",
        "name": "プログラミング言語",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4d27L1",
        "name": "ＶＬＳＩ工学Ⅱ",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4e01L1",
        "name": "電気材料基礎論",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4f01L1",
        "name": "バイオエレクトロニクス",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4505L1",
        "name": "環境電気工学",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4c21L1",
        "name": "電子材料プロセス",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4c25L1",
        "name": "応用電気工学",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4d20L1",
        "name": "通信網工学",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4d28L1",
        "name": "サイバーセキュリティ",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4c20L1",
        "name": "光電子工学II",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4d22L1",
        "name": "情報セキュリティ",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4208L1",
        "name": "電子物性第二",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4703L1",
        "name": "グローバルシステム工学",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4d24L1",
        "name": "ＶＬＳＩアーキテクチャ",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4207L1",
        "name": "半導体物性工学",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4c17L1",
        "name": "宇宙電気電子システム工学",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4504L1",
        "name": "環境学特別講義",
        "credit": 1.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4c18L1",
        "name": "ワイヤレスエレクトロニクス",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4604L1",
        "name": "モーションコントロール",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4c16L1",
        "name": "電気系特別講義第一",
        "credit": 1.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4c19L1",
        "name": "電気自動車工学",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4803S1",
        "name": "電気機器設計法演習",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4d23L1",
        "name": "メディアコンテンツ特別講義I",
        "credit": 1.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4c26L1",
        "name": "電気系特別講義第二",
        "credit": 1.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "EE4506L1",
        "name": "エネルギー総合学基礎論",
        "credit": 2.0,
        "A1": "Ⓐ",
        "A2": "Ⓐ",
        "AS": "Ⓐ",
        "BS": "Ⓐ",
        "B1": "Ⓐ",
        "B2": "Ⓐ"
    },
    {
        "id": "CO4003S2",
        "name": "アカデミック・ライティング（月曜開講）",
        "credit": 1.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "CO4004S2",
        "name": "アカデミック・ライティング（水曜開講）",
        "credit": 1.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "CO4005S2",
        "name": "アカデミック・プレゼンテーション（月曜開講）",
        "credit": 1.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "CO4006S2",
        "name": "アカデミック・プレゼンテーション（水曜開講）",
        "credit": 1.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "CO4400L1",
        "name": "特許法",
        "credit": 1.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "CO4430L1",
        "name": "職業指導",
        "credit": 2.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "CO4500L1",
        "name": "エネルギーと社会",
        "credit": 2.0,
        "A1": "",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "CO4810P1",
        "name": "工学とデザイン",
        "credit": 2.0,
        "A1": "※",
        "A2": "※",
        "AS": "※",
        "BS": "※",
        "B1": "※",
        "B2": "※"
    },
    {
        "id": "CO4d10L1/CO4d11L1 ",
        "name": "スパコンプログラミング",
        "credit": 2.0,
        "A1": "",
        "A2": "※",
        "AS": "",
        "BS": "",
        "B1": "",
        "B2": ""
    },
    {
        "id": "CO4g77P2 ",
        "name": "創造的ものづくりプロジェクトⅢQ‐半導体デザインハッカソン‐",
        "credit": 2.0,
        "A1": "",
        "A2": "※",
        "AS": "※",
        "BS": "",
        "B1": "",
        "B2": ""
    }
]